'use client';

import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { useState } from 'react';

import BurgerMenuIcon from '@/assets/images/burger-menu.svg';
import TopTear from '@/assets/images/hero-top-tear.svg';
import { Profile } from '@/features/authentication/components/profile/Profile.component';
import { SiteHeaderType } from '@/features/home/types';
import { LanguageSelector } from '@/features/site-language/components/language-selector/LanguageSelector.component';

import { MobileNavigation } from '../mobile-navigation/MobileNavigation.component';
import { Modal } from '../modal/Modal.component';

import styles from './GlobalHeader.module.scss';
export const GlobalHeader = ({
    headerData,
    currentLang,
}: {
    headerData: SiteHeaderType;
    currentLang: string;
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const pathname = usePathname();
    const router = useRouter();
    const { data: session } = useSession();

    const goToSection = (id: string): void => {
        if (!id) return;
        setIsOpen(false);

        // if homepage, scroll to section, otherwise reroute
        if (pathname.match(/^\/[\w-]*$/i)) {
            const yOffset = -100;
            const element = document.getElementById(id);
            const y = element ? element.getBoundingClientRect()?.top + window.scrollY + yOffset : 0;

            window.scrollTo({
                top: y,
                behavior: 'smooth',
            });
        } else {
            router.push(`/#${id}`);
        }
    };

    const handleMobileToggle = (openOrClose: boolean): void => setIsOpen(openOrClose);

    return (
        <>
            <header className={styles.header}>
                <div className={styles.container}>
                    <Link href={`/${currentLang}`} aria-label="Return to Home">
                        <Image
                            className={styles.gameLogo}
                            src="/assets/images/redacted-logo.svg"
                            alt="Redacted Logo"
                            role="navigation"
                            width="3240"
                            height="772"
                        />
                    </Link>
                    <div className={styles.actionsWrapper}>
                        <button
                            className={styles.desktopElement}
                            onClick={() => goToSection('newsletter-section')}
                            id="subscribe_header"
                        >
                            <p className={styles.subscribeText}>
                                {headerData?.subscribeText || 'Subscribe'}
                            </p>
                        </button>
                        <Link href={`/${currentLang}/leaderboards`} aria-label="Leaderboards">
                            <button className={styles.desktopElement} id="leaderboards_header">
                                <p className={styles.subscribeText}>
                                    {headerData?.leaderboardsText || 'Leaderboards'}
                                </p>
                            </button>
                        </Link>
                        <Profile
                            strings={headerData}
                            className={clsx(
                                styles.login,
                                styles.desktopElement,
                                session?.user?.sub ? styles.isLoggedIn : null
                            )}
                        />
                        <LanguageSelector
                            currentLang={currentLang}
                            className={styles.desktopElement}
                        />
                        <div className={clsx(styles.buttonWrapper, styles.desktopElement)}>
                            <button
                                className={styles.submit}
                                type="button"
                                onClick={() => goToSection('buy-section')}
                                id="preorder_header"
                            >
                                {headerData?.preorderText || 'Pre-Order'}
                            </button>
                        </div>
                        <button
                            data-testid="burgerMenu"
                            className={styles.burgerMenu}
                            onClick={() => handleMobileToggle(true)}
                            title={'Burger Menu'}
                        >
                            <BurgerMenuIcon className={styles.burgerIcon} />
                        </button>
                    </div>
                </div>
                <TopTear className={styles.tearEffect} />
            </header>
            <Modal
                wrapperClass={styles.modalWrapper}
                overlayClass={styles.modalOverlay}
                closeClass={styles.modalClose}
                open={isOpen}
                close={() => handleMobileToggle(false)}
            >
                <MobileNavigation
                    currentLang={currentLang}
                    headerData={headerData}
                    scrollToSection={goToSection}
                    callback={handleMobileToggle}
                />
            </Modal>
        </>
    );
};
