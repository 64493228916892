'use client';

import Cookies from 'js-cookie';
import Image from 'next/image';
import { usePathname, useRouter } from 'next/navigation';
import { useState } from 'react';

import { Modal } from '@/components/modal/Modal.component';
import { ImageType } from '@/types/Image';

import styles from './KIDPromo.module.scss';

export const KIDPromo = ({ desktop, mobile }: { desktop: ImageType; mobile: ImageType }) => {
    const unSeen = Cookies.get('kid-promo-seen') ? false : true;
    const [isOpen, setIsOpen] = useState(unSeen);
    const pathname = usePathname();
    const router = useRouter();

    const goToSection = (id: string): void => {
        if (!id) return;
        closeModal(false);
        if (pathname.match(/^\/[\w-]*$/i)) {
            const yOffset = -100;
            const element = document.getElementById(id);
            const y = element ? element.getBoundingClientRect()?.top + window.scrollY + yOffset : 0;

            window.scrollTo({
                top: y,
                behavior: 'smooth',
            });
        } else {
            router.push(`/#${id}`);
        }
    };

    const closeModal = (openOrClose: boolean): void => {
        const today = new Date();
        const nextYear = new Date(today);
        nextYear.setFullYear(today.getFullYear() + 1);
        Cookies.set('kid-promo-seen', 'seen', {
            expires: nextYear,
        });
        setIsOpen(openOrClose);
    };

    return (
        <>
            <Modal open={isOpen} close={() => closeModal(false)}>
                <button
                    className={styles.linkWrap}
                    onClick={() => goToSection('kid-signup')}
                    data-testid="desktop-button"
                >
                    <Image
                        className={styles.desktop}
                        src={desktop.src}
                        alt="Krafton ID Login Rewards"
                        role="button"
                        width={desktop.width}
                        height={desktop.height}
                        priority
                    />
                </button>
                <button
                    className={styles.linkWrap}
                    onClick={() => goToSection('kid-signup')}
                    data-testid="mobile-button"
                >
                    <Image
                        className={styles.mobile}
                        src={mobile.src}
                        alt="Krafton ID Login Rewards"
                        role="button"
                        width={mobile.width}
                        height={mobile.height}
                        priority
                    />
                </button>
            </Modal>
        </>
    );
};
